
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import cloneDeep from 'lodash/cloneDeep';
import { GetStaticProps, NextPage } from 'next';
import { unstable_serialize as infSerialize } from 'swr/infinite';
import { unstable_serialize } from 'swr';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import LandingPage from '../components/LandingPage/LandingPage';
import { CountryAndLocale } from '../external';
import { removeHomeCategory } from '../lib/category';
import { createUrlParams } from '../lib/params';
import { parseResponse } from '../services/useApiHelpers';
import { ProductBaseIncludes, ProductListFieldsQuery, ResourceTag, SUPPLIERS_TO_FETCH_ON_CLIENT, SUPPLIERS_TO_SHOW, TOP_LEVEL_CATEGORIES_QUERY, } from '../utils/constants';
import fetcher, { strapiFetcher } from '../utils/fetcher';
import { getCountryAndLocaleStrings, getCurrencyFromCountryAndLocale } from '../utils/locales';
import { Catalog } from '../utils/types/Product';
import { WithFallback } from '../utils/types/misc';
import PageWithFallback from '../components/Catalog/WithFallback';
import { getSEOProps } from '../next-seo.config';
import { countryAndLocaleList, getBasePathWithLocale } from '../utils/urls';
import { buildStrapiUrl } from '../utils/strapi';
import { StrapiFooterResp } from '../utils/types/strapi';
const Home: NextPage = () => {
    const { locale: countryAndLocaleString = '' } = useRouter();
    const hreflangUrls = countryAndLocaleList.reduce((prev, countryAndLocale) => ({ ...prev, [countryAndLocale]: getBasePathWithLocale(countryAndLocale) }), { [countryAndLocaleString]: getBasePathWithLocale(countryAndLocaleString as CountryAndLocale) });
    const canonicalUrl = hreflangUrls[countryAndLocaleString];
    return (<div className="max-width-lg">
      <NextSeo {...getSEOProps()({
        hreflangUrls,
        canonicalUrl,
    })}/>
      <LandingPage />
    </div>);
};
export default PageWithFallback(Home);
const getStaticProps: GetStaticProps = async ({ locale: countryAndLocale, defaultLocale, }) => {
    const url = process.env.NEXT_PUBLIC_API_URL;
    const { locale, country } = getCountryAndLocaleStrings((countryAndLocale || defaultLocale!));
    const res: {
        props: WithFallback<Object>;
        revalidate: number;
    } = {
        props: { fallback: {} },
        revalidate: Number(process.env.REGENERATE_INTERVAL) || 60,
    };
    const currency = getCurrencyFromCountryAndLocale(countryAndLocale as CountryAndLocale);
    try {
        const optionsUrl = `${url}/jsonapi/default?currency=${currency}&locale=${locale}`;
        const catalogUrl = `${url}/jsonapi/default/catalog?currency=${currency}&locale=${locale}&${TOP_LEVEL_CATEGORIES_QUERY}`;
        const supplierUrl = `${url}/jsonapi/default/supplier?currency=${currency}&locale=${locale}&include=media,text,supplier/address,supplier/service&sort=supplier.address.position&page[limit]=${SUPPLIERS_TO_SHOW}`;
        const [optionsResp, categoriesData, supplierResp,] = await Promise.all([
            fetcher(optionsUrl, 'OPTIONS').then((resp) => resp.json()),
            fetcher(catalogUrl).then((resp) => resp.json()),
            fetcher(supplierUrl).then((resp) => resp.json()),
        ]);
        const { data: categories } = parseResponse<Catalog>(cloneDeep(categoriesData));
        const mainCategories = removeHomeCategory(categories).map((cat) => cat.id);
        const mainCategoriesQuery = createUrlParams({
            filter: {
                f_catid: mainCategories,
            },
        });
        const newProductsQuery = createUrlParams({
            filter: {
                f_catid: mainCategories,
            },
            sort: '-ctime',
        });
        // Fetch products for current category
        const query = `?currency=${currency}&locale=${locale}&country=${country}&include=${ProductBaseIncludes.join(',')}${mainCategoriesQuery}&page[limit]=10${ProductListFieldsQuery}`;
        // Fetch new products
        const queryNew = `?currency=${currency}&locale=${locale}&country=${country}&include=${ProductBaseIncludes.join(',')}${newProductsQuery}&page[limit]=10${ProductListFieldsQuery}`;
        const productUrl = `${url}/jsonapi/default/${ResourceTag.product}${query}`;
        const newProductUrl = `${url}/jsonapi/default/${ResourceTag.product}${queryNew}`;
        const productResp = await fetcher(productUrl).then((resp) => resp.json());
        const newProductResp = await fetcher(newProductUrl).then((resp) => resp.json());
        const fetchParams = { method: 'OPTIONS' };
        // Strapi footer data
        // Workaround to include footer content via SSG
        // TODO: Implement globally to Layout component in Next.js 13+
        const footerContentUrl = buildStrapiUrl({
            endpoint: 'footers',
            locale,
            queryParams: `&filters[country][countryCode][$eqi]=${country}&populate[footerColumns][populate][0]=footerColumnLinks&populate[footerBottom][populate][0]=footerBottomLinks&populate[footerImages][populate]=*`,
        });
        const footerContentResp = await strapiFetcher<StrapiFooterResp>(footerContentUrl);
        // Create url matching query used on client for SWR to use fallback properly,
        // even though data is smaller
        const supplierUrlForClientSide = supplierUrl
            .replace(`page[limit]=${SUPPLIERS_TO_SHOW}`, `page[limit]=${SUPPLIERS_TO_FETCH_ON_CLIENT}`);
        res.props = {
            fallback: {
                footerContentResp,
                [unstable_serialize([optionsUrl, JSON.stringify(fetchParams)])]: optionsResp,
                [infSerialize(() => productUrl)]: productResp,
                [infSerialize(() => newProductUrl)]: newProductResp,
                [infSerialize(() => catalogUrl)]: categoriesData,
                [infSerialize(() => supplierUrlForClientSide)]: supplierResp,
            },
        };
    }
    catch (e) {
        console.error('Could not generate products catalogue - ', e);
    }
    return res;
};

    async function __Next_Translate__getStaticProps__1961aef458c__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1961aef458c__ as getStaticProps }
  